import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import zhHant from "vuetify/es5/locale/zh-Hant";

export default new Vuetify({
  lang: {
    locales: { zhHant },
    current: "zhHant"
  }
});
