<template>
  <div class="header">
    <v-toolbar dense max-height="80px" height="80px">
      <!-- Mobile-Start -->
      <v-app-bar-nav-icon color="primary" class="hidden-sm-and-up" @click="turnOnDrawer()"> </v-app-bar-nav-icon>
      <v-spacer class="hidden-sm-and-up"> </v-spacer>
      <v-spacer class="hidden-sm-and-up"> </v-spacer>
      <!-- Mobile-End -->

      <v-toolbar-title>
        <router-link to="/">
          <v-img :src="require('@/assets/images/mall/logo.png')" class="header__logo" width="125px" height="30px">
          </v-img>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items class="header__menu__items hidden-sm-and-down" v-if="$store.state.token">
        <v-btn text width="80px" to="/"> 首頁 </v-btn>
        <v-btn text width="80px" to="/news"> 最新消息 </v-btn>
        <v-btn text width="80px" to="/calendar"> 行事曆 </v-btn>
        <v-btn text width="80px" to="/resell" v-if="$store.state.userInfo.role_id == 20"> 重銷商城 </v-btn>
        <v-btn text width="80px" to="/vip_store" v-if="$store.state.userInfo.role_id == 20"> VIP商城 </v-btn>
        <v-menu open-on-hover down offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text width="80px" v-bind="attrs" v-on="on" v-if="$store.state.userInfo.role_id == 20">
              組織專區
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, index) in menuItem" :key="index">
              <v-list-item-title @click="openUrl(item.url)">{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          text
          color="primary"
          class="header__menu__cart"
          width="155px"
          to="/shoppingcart"
          v-if="$store.state.token && $store.state.userInfo.role_id == 20"
        >
          <v-badge v-if="$store.state.userCart.length != 0" :content="$store.state.userCart.length" left color="green">
            <v-icon>mdi-cart-outline</v-icon>
          </v-badge>
          <v-icon v-if="$store.state.userCart.length == 0">mdi-cart-outline</v-icon>
          購物車
        </v-btn>
        <v-btn
          text
          color="primary"
          class="header__menu__login"
          width="155px"
          to="/login"
          v-if="!$store.state.userInfo.name"
        >
          <v-icon>mdi-account-outline</v-icon>
          會員登入
        </v-btn>
        <v-menu offset-y v-if="$store.state.userInfo.name">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text width="155px" color="primary" dark v-bind="attrs" v-on="on">
              <v-icon>mdi-account-outline</v-icon>
              {{ $store.state.userInfo.name }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index" :to="item.url">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>

      <!-- Mobile-Start -->
      <v-toolbar-items class="hidden-sm-and-up">
        <v-btn color="primary" icon medium to="/shoppingcart">
          <v-badge v-if="$store.state.userCart.length != 0" :content="$store.state.userCart.length" left color="green">
            <v-icon>mdi-cart-outline</v-icon>
          </v-badge>
          <v-icon v-if="$store.state.userCart.length == 0">mdi-cart-outline</v-icon>
        </v-btn>
        <v-menu offset-y v-if="$store.state.userInfo.name">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="primary" dark v-bind="attrs" v-on="on">
              <v-icon>mdi-account-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index" :to="item.url">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <!-- Mobile-End -->
    </v-toolbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    menuItem: [
      {
        title: '主架構組織圖',
        url: 'http://web.hilary.com.tw/mbrSite/BusinessTools/reports/networkChart1.aspx',
      },
      {
        title: '組織圖含業績',
        url: 'http://web.hilary.com.tw/mbrSite/BusinessTools/reports/networkChart.aspx',
      },
      {
        title: 'VIP組織圖',
        url: 'http://web.hilary.com.tw/mbrSite/BusinessTools/reports/binaryNetworkChart.aspx',
      },
      {
        title: '重銷組織圖',
        url: 'http://web.hilary.com.tw/mbrSite/BusinessTools/reports/reuseNetworkChart.aspx',
      },
      {
        title: '獎金明細',
        url: 'http://web.hilary.com.tw/mbrSite/BusinessTools/reports/commStatement.aspx',
      },
    ],
    items: [],
    role_id_20: [
      { title: '我的帳戶', url: '/account' },
      { title: '修改密碼', url: '/changePassword' },
      { title: '訂單查詢', url: '/order' },
      { title: '顧客名單', url: '/customerlist' },
      // { title: "上傳照片", url: "/identification" },
      { title: '上課條碼', url: '/classbarcode' },
      { title: '登出', url: '/logout' },
    ],
    role_id_10: [
      { title: '我的帳戶', url: '/account' },
      { title: '修改密碼', url: '/changePassword' },
      { title: '訂單查詢', url: '/order' },
      // { title: "上傳照片", url: "/identification" },
      { title: '登出', url: '/logout' },
    ],
  }),
  created() {
    let updateMenu = setInterval(() => {
      switch (this.$store.state.userInfo.role_id) {
        case 20:
          this.items = this.role_id_20.map((element) => {
            return { ...element };
          });
          clearInterval(updateMenu);
          break;
        case 10:
          this.items = this.role_id_10.map((element) => {
            return { ...element };
          });
          clearInterval(updateMenu);
          break;
        default:
          break;
      }
    }, 1000);
  },
  methods: {
    turnOnDrawer() {
      this.$store.dispatch('doSwitchDrawer', true);
    },
    openUrl(item) {
      window.open(item);
    },
  },
};
</script>

<style lang="scss">
.header .v-toolbar__content {
  padding-right: 0px;
}
.header__menu__items a:hover span {
  color: #0075ff !important;
}
.header__menu__cart:hover,
.header__menu__login:hover {
  background-color: #0075ff !important;
  span {
    color: #ffffff !important;
  }
}
</style>
