<template>
  <v-app>
    <Header></Header>
    <Drawer></Drawer>
    <v-main>
      <router-view></router-view>
      <BacktoTop></BacktoTop>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from "./components/common/Header";
import Drawer from "./components/common/Drawer";
import Footer from "./components/common/Footer";
import BacktoTop from "@/components/common/BacktoTop";

export default {
  name: "App",

  components: {
    Header,
    Drawer,
    Footer,
    BacktoTop
  }
};
</script>
