<template>
  <div class="drawer">
    <v-navigation-drawer
      v-model="drawerStatus"
      fixed
      width="100vw"
      height="100vh"
      color="#00297B"
    >
      <v-btn
        @click="doSwitchDrawer(false)"
        icon
        medium
        color="white"
        fixed
        class="ma-5"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-col>
        <v-btn
          large
          text
          block
          class="white--text mt-15"
          to="/login"
          v-if="!$store.state.userInfo.name"
        >
          <h2>會員登入/註冊</h2>
        </v-btn>
        <v-btn
          large
          text
          block
          class="white--text mt-15"
          v-if="$store.state.userInfo.name"
        >
          <h2>{{ $store.state.userInfo.name }}, 您好</h2>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn large text block class="white--text" to="/">
          <h2>首頁</h2>
        </v-btn>
      </v-col>
      <v-col v-if="$store.state.userInfo.role_id == 20">
        <v-btn large text block class="white--text" to="/news">
          <h2>最新消息</h2>
        </v-btn>
      </v-col>
      <v-col v-if="$store.state.userInfo.role_id == 20">
        <v-btn large text block class="white--text" to="/resell">
          <h2>重銷商城</h2>
        </v-btn>
      </v-col>
      <v-col v-if="$store.state.userInfo.role_id == 20">
        <v-btn large text block class="white--text" to="/vip_store">
          <h2>VIP商城</h2>
        </v-btn>
      </v-col>
      <v-col v-if="$store.state.token">
        <v-btn large text block class="white--text" to="/order">
          <h2>訂單查詢</h2>
        </v-btn>
      </v-col>
      <v-col>
        <v-menu
          open-on-hover
          down
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large text block class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <h2>組織專區</h2>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in menuItem"
              :key="index"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-row justify="center" class="mt-10">
        <v-col cols="6" v-if="$store.state.token">
          <v-btn block large text outlined rounded color="white" to="/logout">
            <h2>會員登出</h2>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-15">
        <v-col cols="3">
          <v-btn large text block color="white" to="#">
            <h3>訂購須知</h3>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn large text block color="white" to="/privacy">
            <h3>隱私權政策</h3>
          </v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: ()=>({
    menuItem: [
      { title: '主架構組織圖',url: ""},
      { title: '組織圖含業績',url: ""},
      { title: 'VIP組織圖',url: ""},
      { title: '重銷組織圖',url: ""},
      { title: '獎金明細',url: ""},
    ],
  }),
  computed: {
    ...mapGetters(["drawerStatus"])
  },
  methods: {
    ...mapActions(["doSwitchDrawer"])
  },
  watch: {
    //監聽路由變化，若有變化將 Drawer 狀態更改為關閉
    $route(to, from) {
      this.doSwitchDrawer(false);
    }
  }
};
</script>

.<style lang="scss">
.drawer .theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-color: unset;
}
</style>
