import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {},
    drawerisOn: false,
    userCart: []
  },
  getters: {
    cartList(state) {
      return state.userCart;
    },
    drawerStatus(state) {
      return state.drawerisOn;
    }
  },

  mutations: {
    setToken(state, param) {
      state.token = param;
      localStorage.setItem("token", state.token);
    },
    setuserInfo(state, parm) {
      state.userInfo = parm;
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    addItemToCart(state, param) {
      let data = {
        id: param.id,
        store_name: param.store_name,
        product_name: param.product_name,
        price: param.price,
        pv: param.pv,
        product_photo: param.product_photo,
        amount: param.amountShow,
        set_id: param.set_id != "" ? param.set_id : null,
        set_name: param.set_name != ""? param.set_name : null,
      };
      let index = -1;
      index = state.userCart.findIndex(item => {
        return item.id == data.id;
      });
      if (index == -1) {
        state.userCart.push(data);
      } else {
        state.userCart[index].amount =
          state.userCart[index].amount + data.amount;
      }
    },
    addItemCart(state, param) {
      let index = state.userCart.findIndex(item => {
        return item.id == param;
      });
      state.userCart[index].amount++;
    },
    delItemCart(state, param) {
      let index = state.userCart.findIndex(item => {
        return item.id == param;
      });
      if (state.userCart[index].amount < 1) {
        state.userCart.splice(index, 1);
      } else {
        state.userCart[index].amount--;
      }
    },
    removeItemCart(state, param) {
      let index = state.userCart.findIndex(item => {
        return item.id == param;
      });
      state.userCart.splice(index, 1);
    },
    cleanUserCart(state) {
      state.userCart = [];
    },
    switchDrawer(state, param) {
      state.drawerisOn = param;
    }
  },

  actions: {
    doSetToken(context, param) {
      context.commit("setToken", param);
    },
    doSetUserInfo(context, parm) {
      context.commit("setuserInfo", parm);
    },
    doAddItemToCart(context, param) {
      context.commit("addItemToCart", param);
    },
    doAddItemCart(context, param) {
      context.commit("addItemCart", param);
    },
    doDelItemCart(context, param) {
      context.commit("delItemCart", param);
    },
    doRemoveItemCart(context, param) {
      context.commit("removeItemCart", param);
    },
    doCleanUserCart(context) {
      context.commit("cleanUserCart");
    },
    doSwitchDrawer(context, param) {
      context.commit("switchDrawer", param);
    }
  },

  modules: {}
});
